import request from '@/utils/request.js';
import axios from 'axios';
import { config } from '@/config/index.js';

export const getUserProfile = async () => {
  return request(`/user/profile`, {
    method: 'GET',
  });
};

export const updateUserProfile = async user => {
  return request(`/user/profile`, {
    method: 'PUT',
    data: user,
  });
};

export const logout = async () => {
  return axios.get(`${config.API_BASE_URL}/auth/logout`, {
    method: 'GET',
    withCredentials: true,
  });
};
