// src/router/routerIndex.js
import React from 'react';
import Report from '@/page/Report/index.js';
import NewChat from '@/page/NewChat/index.js';
import HomePage from '@/page/Home/index.js';
import NotFound from '@/page/NotFound/index.js';
import Setting from '@/page/Setting/index.js'; // 新增导入
import { createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    children: [
      {
        index: true,
        element: <NewChat isFromHome={true} />,
      },
      {
        path: '/chat/:externalRef',
        element: <NewChat />,
      },
      {
        path: '/report/:externalRef',
        element: <Report />,
      },
      {
        path: '/setting',
        element: <Setting />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
