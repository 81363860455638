import { configureStore } from '@reduxjs/toolkit';
import chatSlice from '@/store/modules/chatSlice.js';
import authSlice from '@/store/modules/authSlice.js';
import globalSlice from './modules/globalSlice.js';

const store = configureStore({
  reducer: {
    global: globalSlice,
    chat: chatSlice,
    auth: authSlice,
  },
});

export default store;
