import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { checkLoginStatusService } from '@/services/authService.js';

export const checkLoginStatus = createAsyncThunk('auth/checkLoginStatus', async () => {
  return await checkLoginStatusService();
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(checkLoginStatus.fulfilled, (state, action) => {
      state.isLoggedIn = action.payload.loggedIn;
    });
  },
});

export default authSlice.reducer;
