import request from '@/utils/request.js';

export const ConversationChat = async (data, signal) => {
  return request(`/conversation/chat`, {
    method: 'POST',
    data,
    signal,
  });
};

export const fetchConversationId = async () => {
  return request(`/conversation/createConversation`, {
    method: 'POST',
  });
};

export const getAllConversations = async () => {
  return request(`/conversation/getAllConversations`, {
    method: 'GET',
  });
};

export const getConversationMessages = async externalRef => {
  return request(`/conversation/${externalRef}/getConversationMessages`, {
    method: 'GET',
  });
};

export const updateConversationNameAPI = async data => {
  return request(`/conversation/updateConversationName`, {
    method: 'POST',
    data,
  });
};

export const updateMessageByConversationER = async data => {
  return request(`/conversation/updateMessageByConversationExternalRef`, {
    method: 'POST',
    data,
  });
};

export const deleteConversationApi = async externalRef => {
  return request(`/conversation/${externalRef}/deleteConversation`, {
    method: 'DELETE',
  });
};
