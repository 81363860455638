import React, { useEffect, useState } from 'react';
import {
  Layout,
  Select,
  Radio,
  Button,
  Avatar,
  Card,
  Divider,
  Form,
  Input,
  Upload,
  message,
  Spin,
  Row,
  Col,
} from 'antd';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate, useOutletContext } from 'react-router-dom';
import './index.css';
import '@/page/Home/index.css';
import { updateUserProfile } from '@/services/user.js';
import axios from 'axios';
import { uploadAttachment } from '@/services/attachment.js';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setThemeMode } from '@/store/modules/globalSlice.js';

const { Content } = Layout;

const SettingPage = () => {
  const navigate = useNavigate();
  const { profile, setProfile, setAvatarMenuUrl, isMobile } = useOutletContext();
  const [form] = Form.useForm();

  const [avatarUrl, setAvatarUrl] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSave = async formValues => {
    setLoading(true);
    const avatarParam = profile.user.avatar;
    const updatedProfile = await updateUserProfile({
      ..._.omit(formValues, 'avatar'),
      avatarParam,
      updateAvatar: avatarUrl !== avatarParam,
    });
    dispatch(setThemeMode(formValues.appearance));
    setProfile(updatedProfile);
    setLoading(false);
  };

  const handleClose = () => {
    navigate('/');
  };

  useEffect(() => {
    if (profile && profile?.user) {
      form.setFieldsValue({
        appearance: profile.user.appearance || 'Light',
        language: profile.user.language || 'English',
        name: profile.user.name || '',
        email: profile.user.email || '',
      });
      setAvatarUrl(profile.user.avatar);
    }
  }, [profile, form]);

  return (
    <Card
      title={
        <>
          <Row
            justify="space-between"
            align="middle"
            style={{ padding: '0 1rem', lineHeight: '2rem' }}
          >
            <Col>
              <label style={{ margin: 0, fontSize: 'medium' }}>Setting</label>
            </Col>
            <Col>
              <Button icon={<CloseOutlined />} onClick={handleClose} className="close-button" />
            </Col>
          </Row>
        </>
      }
    >
      <Spin spinning={loading} tip="Loading...">
        <Content className={isMobile ? 'content-area-mobile' : 'content-area'}>
          <Form form={form} layout="horizontal" onFinish={handleSave}>
            <Card title="Account" className="card-container">
              <Form.Item
                name="avatar"
                label="Avatar"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
                style={{ textAlign: 'right', paddingRight: '10px' }}
              >
                <Upload
                  name="avatar"
                  showUploadList={false}
                  beforeUpload={async file => {
                    const isImage = file.type.startsWith('image/');
                    if (!isImage) {
                      message.error('You can only upload image files!');
                      return false;
                    }
                    const isSmallEnough = file.size / 1024 / 1024 < 2;
                    if (!isSmallEnough) {
                      message.error('Image must be smaller than 2MB!');
                      return false;
                    }

                    try {
                      setLoading(true);
                      const { signedPutUrl, signedGetUrl } = await uploadAttachment(file, true);
                      await axios.put(signedPutUrl, file, {
                        headers: {
                          'Content-Type': file.type,
                        },
                      });
                      setAvatarUrl(signedGetUrl);
                      setAvatarMenuUrl(signedGetUrl);
                      message.success('Avatar uploaded successfully!');
                    } catch (error) {
                      message.error('Failed to upload avatar. Please try again.');
                    } finally {
                      setLoading(false);
                    }

                    return false;
                  }}
                >
                  <Avatar
                    size={60}
                    src={avatarUrl}
                    style={{ backgroundColor: '#f56a00', cursor: 'pointer', float: 'right' }}
                    icon={!avatarUrl && <UserOutlined />}
                  />
                </Upload>
              </Form.Item>

              <Divider className="row-divider" />
              <br />
              <Form.Item name="name" label="Username">
                <Input className="input-style" />
              </Form.Item>
              <Divider className="row-divider" />
              <br />
              <Form.Item name="email" label="Email">
                <Input className="input-style" />
              </Form.Item>
              <Divider className="row-divider" />
            </Card>

            <Card title="General" className="card-container">
              <Form.Item name="appearance" label="Appearance">
                <Radio.Group className="text-right">
                  <Radio value="light">Light</Radio>
                  <Radio value="dark">Dark</Radio>
                </Radio.Group>
              </Form.Item>
              <Divider className="row-divider" />
              <br />
              <Form.Item name="language" label="Language">
                <Select className="text-right" style={{ width: 100, float: 'right' }}>
                  <Select.Option value="English">English</Select.Option>
                  <Select.Option value="Chinese">Chinese</Select.Option>
                </Select>
              </Form.Item>
              <Divider className="row-divider" />
            </Card>

            <Card title="Security" className="card-container">
              <div className="row-item">
                <label className="text-left">Recent Security Activity</label>
                <div className="text-right">
                  <Button type="default">History</Button>
                </div>
              </div>
              <Divider className="row-divider" />
            </Card>

            <Button type="primary" htmlType="submit" className="save-button">
              Save
            </Button>
          </Form>
        </Content>
      </Spin>
    </Card>
  );
};

export default SettingPage;
