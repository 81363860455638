import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { CloudUploadOutlined, LinkOutlined } from '@ant-design/icons';
import { Bubble, Sender, Attachments } from '@ant-design/x';
import { Button, Flex, message, Result, Row, Space, Typography } from 'antd';
import { fetchConversationId, ConversationChat, getConversationMessages } from '@/services/chat.js';
import CustomBubbleRender from './components/CustomBubbleRender.js';
import { getSignedUrl, uploadToS3 } from '@/services/attachment.js';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import HomeSenderView from './components/HomeSenderView.js';
import { getConversionListThunk } from '@/store/modules/globalSlice.js';

const { Text } = Typography;

const NewChat = props => {
  const { isFromHome } = props;
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userInputValue, setUserInputValue] = useState('');
  const [fileList, setFileList] = React.useState([]);

  const unsavedMessages = useRef(null);
  const { isMobile } = useOutletContext();

  const dispatch = useDispatch();
  const { conversionList } = useSelector(state => state.global);
  const { isLoggedIn } = useSelector(state => state.auth);

  const { externalRef } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      initializeConversation();
    }

    return () => {
      setMessages([]);
      setUserInputValue('');
      setFileList([]);
      setOpen(false);
      setLoading(false);
    };
  }, [externalRef, navigate, isLoggedIn]);

  const initializeConversation = async () => {
    if (externalRef && !unsavedMessages.current) {
      const response = await getConversationMessages(externalRef);
      if (response) {
        response.forEach(item => Object.assign(item, { typing: false }));
        setMessages(response);
      } else {
        setMessages([]);
      }
    } else if (externalRef && unsavedMessages.current) {
      setUserInputValue(unsavedMessages.current.text);
      setFileList(unsavedMessages.current.fileList);
      handleSendMessage(unsavedMessages.current.text);
      unsavedMessages.current = null;
    }
  };

  const uploadFile = async file => {
    const fileResponse = await getSignedUrl(file);
    await uploadToS3(fileResponse.signedPutUrl, file.originFileObj);
    return fileResponse;
  };

  const sharedAttachmentProps = {
    beforeUpload: () => false,
    items: fileList,
    maxCount: 5,
    accept: 'image/jpeg,image/png,image/webp,image/gif',
    onChange: ({ file, fileList }) => {
      // We currently support PNG (.png), JPEG (.jpeg and .jpg), WEBP (.webp), and non-animated GIF (.gif).
      if (!['image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(file.type)) {
        message.error('Only support PNG, JPEG, WEBP, and non-animated GIF!');
        return;
      }
      // 文件大小2mb
      if (file.size > 2 * 1024 * 1024) {
        message.error('File size should be less than 2MB!');
        return;
      }
      if (Array.isArray(fileList) && fileList.length > 5) {
        message.error('You can only upload 5 files at most!');
        return;
      }
      setFileList(fileList);
    },
  };

  const getPlaceholderFn = inlinePlaceholder => {
    return type =>
      type === 'drop'
        ? {
            title: 'Drop file here',
          }
        : inlinePlaceholder;
  };

  const headerNode = (
    <Sender.Header title="Upload" open={open} onOpenChange={setOpen}>
      <Flex vertical align="center" gap="small">
        <Attachments
          {...sharedAttachmentProps}
          disabled={loading}
          placeholder={getPlaceholderFn(
            <Result
              title="Drag and Drop Images Here"
              icon={<CloudUploadOutlined />}
              extra={<Button type="primary">Select Images</Button>}
              style={{
                padding: 0,
              }}
            />
          )}
        />
      </Flex>
    </Sender.Header>
  );

  const resetSender = () => {
    setFileList([]);
    setUserInputValue('');
    setOpen(false);
  };

  const haveAttachments = () => Array.isArray(fileList) && fileList.length > 0;

  const sendFromHomeAction = async text => {
    if (!isFromHome) return;

    unsavedMessages.current = {
      text,
      fileList,
    };
    const res = await fetchConversationId();
    dispatch(getConversionListThunk()).then(() => {
      navigate(`/chat/${res.externalRef}`);
    });
  };

  const handleSendMessage = async text => {
    if (isFromHome) {
      await sendFromHomeAction(text);
      return;
    }

    if (!text.trim()) return; // 防止发送空消息
    setLoading(true);

    const imageExternalRefs = [];
    const signedGetUrls = [];
    const previewUrls = [];

    if (haveAttachments()) {
      const res = await Promise.all(fileList.map(uploadFile));
      res.forEach(item => {
        imageExternalRefs.push(item.externalRef);
        signedGetUrls.push(item.signedGetUrl);
        previewUrls.push(item.signedGetUrl);
      });
    }

    // 添加用户消息到对话框
    const appendMessage = {
      content: {
        type: 'text',
        content: text,
      },
      role: 'user',
      placement: 'end',
      typing: false,
    };

    if (haveAttachments()) {
      Object.assign(appendMessage.content, {
        type: 'image',
        content: {
          text,
          imageUrls: previewUrls,
        },
      });
    }

    setMessages(prevMessages => [...prevMessages, appendMessage]);
    resetSender();
    try {
      // 请求后端接口
      const payload = {
        message: text,
        conversationExternalRef: externalRef,
      };
      if (haveAttachments()) {
        Object.assign(payload, { imageExternalRefs, signedGetUrls });
      }

      const response = await ConversationChat(payload);

      setMessages(prevMessages => [...prevMessages, response]);
    } catch (error) {
      const errorMessage = {
        content: {
          type: 'text',
          content: 'Something went wrong. Please try again later.',
        },
        role: 'assistant',
        placement: 'start',
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const getCurrentConversion = () => {
    return conversionList.find(item => item.externalRef === externalRef);
  };

  const renderJumpBtn = (content, btnText, onClick) => (
    <div className="chat-footer">
      <Row justify="center">
        <Space size="large">
          <Text strong>{content}</Text>
          <Button size="large" type="primary" className="jump-btn" onClick={onClick}>
            {btnText}
          </Button>
        </Space>
      </Row>
    </div>
  );

  const gotoReport = () => {
    const target = getCurrentConversion();
    if (target) {
      navigate('/report/' + target.reportId);
    }
  };

  const renderChatSender = () => {
    let type = '';
    const currentConversion = getCurrentConversion();
    const hasRisk = messages.find(item => item.content?.type === 'risk');
    if (currentConversion && currentConversion.reportId) {
      type = 'reported';
    } else if (hasRisk) {
      type = 'risk';
    }

    switch (type) {
      case 'reported':
        return renderJumpBtn(
          'This dialog has created a report, continue to ask please create a new chat.',
          'Jump to the Report Page',
          gotoReport
        );
      case 'risk':
        return renderJumpBtn('To continue using the system, create a new chat.', 'New Chat', () => {
          navigate('/');
        });
      default:
        return (
          <div style={{ padding: 16 }}>
            <Sender
              header={headerNode}
              loading={loading}
              prefix={
                <Button
                  type="text"
                  icon={<LinkOutlined />}
                  onClick={() => {
                    setOpen(!open);
                  }}
                />
              }
              placeholder="Type your message..."
              value={userInputValue}
              onChange={v => {
                setUserInputValue(v);
              }}
              onSubmit={handleSendMessage}
              onCancel={() => {
                setLoading(false);
                message.error('Cancel sending!');
              }}
            />
          </div>
        );
    }
  };

  const renderContent = () => {
    if (isFromHome) {
      return (
        <div style={{ flex: 1, padding: 16 }}>
          <HomeSenderView />
        </div>
      );
    }

    return (
      <div style={{ flex: 1, overflowY: 'auto', padding: 16 }}>
        <Flex gap="middle" vertical>
          {messages.map(item => (
            <CustomBubbleRender
              key={item.externalRef}
              message={item.content}
              role={item.role}
              createdAt={item.createdAt}
              typing={item.typing}
            />
          ))}

          {loading && (
            <Bubble loading={loading} content="Typing..." role="assistant" placement="start" />
          )}
        </Flex>
      </div>
    );
  };

  return (
    <div
      style={{
        height: isMobile ? 'calc(100vh - 64px)' : '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* 消息展示区域 */}
      {renderContent()}

      {/* 消息输入区域 */}
      {renderChatSender()}
    </div>
  );
};

export default NewChat;
