import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  PlusOutlined,
  UserOutlined,
  EllipsisOutlined,
  RightSquareOutlined,
  LeftSquareOutlined,
  MessageOutlined,
  ProfileOutlined,
  GoogleOutlined,
  FacebookOutlined,
  WindowsOutlined,
  DeleteOutlined,
  EditOutlined,
  MoonOutlined,
  SunOutlined,
  SettingOutlined,
  LogoutOutlined,
  AlignLeftOutlined,
  PlusSquareOutlined,
  AlignRightOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Image,
  Modal,
  Input,
  Row,
  Col,
  notification,
  Popover,
  Button,
  Avatar,
  Dropdown,
  Checkbox,
  Spin,
  Tooltip,
  Typography,
  Space,
  Drawer,
} from 'antd';
import avatarDay from '@/assets/logo.png';
import loginWhite from '@/assets/login-white.png';
import { updateNameAPI } from '@/services/report.js';
import { deleteConversationApi, updateConversationNameAPI } from '@/services/chat.js';
import './index.css';
import { config } from '@/config/index.js';
import { logout, updateUserProfile } from '@/services/user.js';
import { useSelector, useDispatch } from 'react-redux';
import { checkLoginStatus } from '@/store/modules/authSlice.js';
import {
  deleteConversion,
  getConversionListThunk,
  getRepostLitThunk,
  getUserProfileThunk,
  setCurrentTitle,
  setThemeMode,
} from '@/store/modules/globalSlice.js';

const { Content, Sider, Header } = Layout;
const { Text } = Typography;

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { externalRef } = useParams();
  const location = useLocation();

  const [newName, setNewName] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [menuSelectedKeys, setMenuSelectedKeys] = useState([]);
  const [menuOpenKeys, setMenuOpenKeys] = useState([]);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileSiderOpen, setIsMobileSiderOpen] = useState(false);
  const [profile, setProfile] = useState(undefined);
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [avatarMenuUrl, setAvatarMenuUrl] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [renameId, setRenameId] = useState('');
  const [renameType, setRenameType] = useState('');

  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);

  const { userProfile, conversionList, reportList, themeMode, currentTitle } = useSelector(
    state => state.global
  );
  const { isLoggedIn } = useSelector(state => state.auth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setCollapsed(false);
    setIsMobileSiderOpen(false);
  }, [isMobile]);

  useEffect(() => {
    dispatch(checkLoginStatus());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      getInitialData();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setProfile(userProfile);
    if (userProfile && userProfile.user) {
      dispatch(setThemeMode(userProfile.user.appearance));
      setAvatarMenuUrl(userProfile.user.avatar);
    }
  }, [userProfile]);

  useEffect(() => {
    const defaultMenuItems = [
      {
        key: '/',
        icon: <PlusOutlined />,
        label: 'New chat',
      },
      {
        key: '/Conversation',
        icon: <MessageOutlined />,
        label: 'Conversation',
        children: renderConversionItem(),
      },
      {
        key: '/Report',
        icon: <ProfileOutlined />,
        label: 'Report',
        children: renderReportItem(),
      },
    ];

    if (!isMobile && collapsed) {
      // 当折叠时，添加展开按钮到菜单项的第一个位置
      setMenuItems([
        {
          key: '/Expand',
          icon: <RightSquareOutlined />,
          label: 'Expand',
          onClick: () => setCollapsed(false),
        },
        ...defaultMenuItems,
      ]);
    } else {
      setMenuItems(defaultMenuItems);
    }
  }, [collapsed, conversionList, reportList]);

  useEffect(() => {
    setDefaultMenuKeys();
    if (isMobile) {
      setIsMobileSiderOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile) {
      setHeaderTitle();
    } else {
      dispatch(setCurrentTitle('Scams.Report'));
    }
  }, [location.pathname, conversionList, reportList]);

  const setHeaderTitle = () => {
    const pathname = location.pathname;
    if (/\/chat\/*/.test(pathname)) {
      const target = conversionList.find(item => item.externalRef === externalRef);
      dispatch(setCurrentTitle(target?.name));
    } else if (/\/report\/*/.test(pathname)) {
      const target = reportList.find(item => item.externalRef === externalRef);
      dispatch(setCurrentTitle(target?.reportName));
    } else if (pathname === '/setting') {
      dispatch(setCurrentTitle('Setting'));
    } else {
      dispatch(setCurrentTitle('Scams.Report'));
    }
  };

  const setDefaultMenuKeys = () => {
    const pathname = location.pathname;
    if (/\/chat\/*/.test(pathname)) {
      !isMobile && setMenuOpenKeys(['/Conversation']);
      setMenuSelectedKeys(location.pathname);
    } else if (/\/report\/*/.test(pathname)) {
      !isMobile && setMenuOpenKeys(['/Report']);
      setMenuSelectedKeys(location.pathname);
    } else {
      setMenuSelectedKeys([]);
      setMenuOpenKeys([]);
    }
  };

  const getInitialData = async () => {
    try {
      setLoading(true);
      dispatch(getUserProfileThunk());
      dispatch(getConversionListThunk()).then(() => {
        setDefaultMenuKeys();
      });
      dispatch(getRepostLitThunk()).then(() => {
        setDefaultMenuKeys();
      });
    } catch (error) {
      console.error('Error during data fetching:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuClick = e => {
    e.stopPropagation();
  };

  const renderConversionItem = () => {
    return (conversionList || []).map(item => ({
      key: `/chat/${item.externalRef}`,
      label: (
        <Tooltip title={isMobile ? null : item.name}>
          <Row style={{ width: '100%' }}>
            <Col flex="1" style={{ overflow: 'hidden' }}>
              {item.reportId ? (
                <>
                  <span style={{ color: '#FF6633' }}>(reported)</span>
                  {item.name}
                </>
              ) : (
                item.name
              )}
            </Col>
            <Col flex="24px">
              <Popover content={editConversation(item.externalRef, item.reportId)} trigger="click">
                <EllipsisOutlined onClick={handleMenuClick} />
              </Popover>
            </Col>
          </Row>
        </Tooltip>
      ),
    }));
  };

  const renderReportItem = () => {
    return (reportList || []).map(item => ({
      key: `/report/${item.externalRef}`,
      label: (
        <Tooltip title={item.reportName}>
          <Row style={{ width: '100%' }}>
            <Col flex="1" style={{ overflow: 'hidden' }}>
              {item.reportName}
            </Col>
            <Col flex="24px">
              <Popover content={editReport(item.externalRef)} trigger="click">
                <EllipsisOutlined onClick={handleMenuClick} />
              </Popover>
            </Col>
          </Row>
        </Tooltip>
      ),
    }));
  };

  const itemClick = (id, type, event) => {
    event.stopPropagation();
    setNewName('');
    setRenameId(id);
    setIsModalOpen(true);
    setRenameType(type);
  };

  const editReport = id => (
    <div>
      <p>
        <Button
          icon={<EditOutlined />}
          className="edit-button"
          size={'small'}
          onClick={e => itemClick(id, 'report', e)}
        >
          Rename
        </Button>
      </p>
    </div>
  );

  const deleteConversation = (id, event) => {
    event.stopPropagation();
    if (externalRef && id === externalRef) {
      navigate('/');
    }
    dispatch(deleteConversion(id));
    deleteConversationApi(id);
  };

  const editConversation = (id, hasReport) => (
    <div>
      <p>
        <Button
          icon={<EditOutlined />}
          className="edit-button"
          size={'small'}
          onClick={e => itemClick(id, 'conversation', e)}
        >
          Rename
        </Button>
      </p>
      {!hasReport && (
        <p>
          <Button
            danger
            icon={<DeleteOutlined />}
            className="edit-button"
            size={'small'}
            onClick={e => deleteConversation(id, e)}
          >
            Delete
          </Button>
        </p>
      )}
    </div>
  );

  const handleLoginModalOpen = () => {
    setIsLoginModalOpen(true);
  };

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  const handleLogin = provider => {
    window.location.href = `${config.API_BASE_URL}/auth/login/${provider}`;
    setIsLoginModalOpen(false);
  };

  const handleSignout = async () => {
    await logout();
    navigate('/');
    window.location.reload();
  };

  const handleOk = async () => {
    if ('' === newName || newName.length < 1) {
      notification.open({
        message: 'Name modification prompt',
        description: 'The length of the name should be between 1 and 20.',
        duration: 3,
      });
      return;
    }

    const fromData = {
      bridgeReportName: newName,
      externalRef: renameId,
    };
    if (renameType === 'report') {
      updateNameAPI(fromData)
        .then(() => {
          window.location.reload();
        })
        .catch(() => {});
    }
    if (renameType === 'conversation') {
      await updateConversationNameAPI({ externalRef: renameId, name: newName });
    }

    handleCancel();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRenameType('');
    setRenameId('');
  };

  const onMenuClick = route => {
    if (route.key !== '/Expand') {
      navigate(route.key);
      setMenuSelectedKeys(route.key);
    }
  };

  const onOpenChange = openKeys => {
    setMenuOpenKeys(openKeys);
  };

  const userSettingMenu = () => (
    <Menu style={{ width: '200px', textAlign: 'center' }}>
      <Menu.Item
        key="0"
        icon={
          <Avatar
            src={avatarMenuUrl}
            style={{ backgroundColor: '#f56a00' }}
            icon={!avatarMenuUrl && <UserOutlined />}
          />
        }
      >
        &nbsp;&nbsp;&nbsp;
        {profile && profile.user && profile.user.name}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        icon={<LogoutOutlined style={{ fontSize: '1.7em' }} />}
        onClick={handleSignout}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Signout
      </Menu.Item>
    </Menu>
  );

  const handleUpdateUser = async changeToDark => {
    const appearance = changeToDark ? 'dark' : 'light';
    const updatedUser = await updateUserProfile({
      appearance,
      avatarParam: profile.user.avatar,
      updateAvatar: false,
    });
    dispatch(setThemeMode(appearance));
    setProfile(updatedUser);
  };

  const renderMenu = () => {
    return (
      <Menu
        selectedKeys={menuSelectedKeys}
        openKeys={menuOpenKeys}
        mode="inline"
        onClick={onMenuClick}
        onOpenChange={onOpenChange}
        items={menuItems}
        style={{ height: '80vh', overflow: 'auto' }}
      />
    );
  };

  const renderSiderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: collapsed ? 'center' : 'flex-start',
          padding: '20px 10px',
        }}
      >
        <Image
          width={50}
          src={avatarDay}
          preview={false}
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer' }}
        />
        {!collapsed && (
          <Space>
            <label
              className="intro-text"
              style={{ marginLeft: '10px', fontWeight: 'bold', cursor: 'pointer' }}
              onClick={() => navigate('/')}
            >
              <Text>Scams Report</Text>
            </label>
            <Text>
              <LeftSquareOutlined
                className="sider-trigger"
                style={{ marginLeft: 'auto', cursor: 'pointer' }}
                onClick={() => setCollapsed(true)}
              />
            </Text>
          </Space>
        )}
      </div>
    );
  };

  const renderSiderFooter = () => {
    return (
      <div className="login-footer">
        {!profile && (
          <UserOutlined
            type="primary"
            block
            onClick={handleLoginModalOpen}
            style={{ fontSize: '1.7em' }}
          >
            Login
          </UserOutlined>
        )}
        {profile && profile.user && (
          <div className="user-actions">
            <Dropdown overlay={userSettingMenu(themeMode)}>
              <Button
                icon={
                  <Avatar
                    src={avatarMenuUrl}
                    style={{ backgroundColor: '#f56a00' }}
                    icon={!avatarMenuUrl && <UserOutlined />}
                  />
                }
                style={{ border: 'none', boxShadow: 'none', backgroundColor: 'inherit' }}
              >
                {!collapsed && profile.user.name}
              </Button>
            </Dropdown>

            {!collapsed && (
              <>
                {themeMode === 'dark' ? (
                  <Text>
                    <MoonOutlined className="icon-style" onClick={() => handleUpdateUser(false)} />
                  </Text>
                ) : (
                  <Text>
                    <SunOutlined className="icon-style" onClick={() => handleUpdateUser(true)} />
                  </Text>
                )}
                <Text>
                  <SettingOutlined className="icon-style" onClick={() => navigate('/setting')} />
                </Text>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderSider = () => {
    return (
      <Sider
        theme={themeMode}
        collapsible
        width={220}
        collapsed={collapsed}
        trigger={null}
        style={{
          height: '100vh',
          position: 'relative',
        }}
      >
        <div>
          {renderSiderHeader()}
          {renderMenu()}
          {renderSiderFooter()}
        </div>
      </Sider>
    );
  };

  const renderHeaderRight = () => {
    if (isLoggedIn) {
      return (
        <Link to="/">
          <Button type="link" ghost icon={<PlusSquareOutlined />}></Button>
        </Link>
      );
    }
    return (
      <Button
        type="primary"
        size="small"
        style={{ borderRadius: '1rem' }}
        onClick={handleLoginModalOpen}
      >
        Login
      </Button>
    );
  };

  const renderMenuLayout = () => {
    if (isMobile) {
      return (
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: themeMode === 'dark' ? '' : '#fff',
            padding: '0 .3rem',
          }}
        >
          <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Col span={2}>
              <Button
                type="link"
                ghost
                icon={<AlignLeftOutlined />}
                onClick={() => setIsMobileSiderOpen(true)}
              ></Button>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography.Text>{currentTitle}</Typography.Text>
            </Col>
            <Col span={2}>{renderHeaderRight()}</Col>
          </Row>
        </Header>
      );
    }
    return renderSider();
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      <Layout>
        {renderMenuLayout()}

        <Layout>
          <Content style={{ width: '100%' }}>
            <Outlet
              context={{
                isMobile,
                profile,
                setProfile,
                setAvatarMenuUrl,
              }}
            />
          </Content>
        </Layout>

        <Drawer
          placement="left"
          width="60%"
          closable={false}
          open={isMobileSiderOpen}
          onClose={() => setIsMobileSiderOpen(false)}
          styles={{ body: { padding: 0 } }}
        >
          <Row justify="end">
            <Button
              type="link"
              ghost
              icon={<AlignRightOutlined />}
              onClick={() => setIsMobileSiderOpen(false)}
            ></Button>
          </Row>
          <Row>{renderMenu()}</Row>
          <Row>{renderSiderFooter()}</Row>
        </Drawer>

        <Modal
          open={isLoginModalOpen}
          onCancel={handleLoginModalClose}
          footer={null}
          centered
          width={400}
        >
          <div style={{ textAlign: 'center' }}>
            <br />
            <h2>Scams Report</h2>
            <br />
            <h1>Welcome!</h1>
            <br />
            <Image src={loginWhite} preview={false} width={150} />
            <br />
            <br />
            <p>Prevent, Detect, Protect</p>
            <br />
            <Checkbox onChange={e => setIsTermsAgreed(e.target.checked)}>
              I agree to the
              <a href="https://www.cyberoo.ai/" target="_blank" rel="noopener noreferrer">
                {' '}
                terms and conditions
              </a>
              .
            </Checkbox>
            <br />
            <br />
            <Button
              icon={<GoogleOutlined />}
              type="primary"
              className="login-button google"
              onClick={() => handleLogin('google')}
              disabled={!isTermsAgreed}
            >
              Continue with Google
            </Button>
            <Button
              icon={<FacebookOutlined />}
              type="primary"
              className="login-button facebook"
              onClick={() => handleLogin('facebook')}
              disabled={!isTermsAgreed}
            >
              Continue with Facebook
            </Button>
            <Button
              icon={<WindowsOutlined />}
              type="primary"
              className="login-button microsoft"
              onClick={() => handleLogin('microsoft')}
              disabled={!isTermsAgreed}
            >
              Continue with Microsoft
            </Button>
          </div>
        </Modal>

        <Modal title="Rename" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <Input
            placeholder="A new name"
            value={newName}
            onChange={e => setNewName(e.target.value)}
            showCount
            maxLength={20}
            minLength={2}
          />
        </Modal>
      </Layout>
    </Spin>
  );
};

export default HomePage;
