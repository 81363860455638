import { addReportAPI } from '@/services/report.js';
import { getRepostLitThunk, updateConversionReportId } from '@/store/modules/globalSlice.js';
import { useRequest } from 'ahooks';
import { Button, Col, Modal, Progress, Row } from 'antd';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

const RiskBubble = props => {
  const { score, content, isComplete } = props;
  const { externalRef } = useParams();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { conversionList } = useSelector(state => state.global);

  const { loading, runAsync: runAddReport } = useRequest(addReportAPI, {
    manual: true,
  });

  const addReport = async () => {
    if (!externalRef) return;
    const reportId = await runAddReport({ chatId: externalRef });
    dispatch(updateConversionReportId({ conversionId: externalRef, reportId }));
    dispatch(getRepostLitThunk());
  };

  const handleOk = () => {
    addReport();
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getCurrentConversion = () => {
    return conversionList.find(item => item.externalRef === externalRef);
  };

  return (
    <div style={{ padding: '0 .8rem' }}>
      <Row>
        <Progress
          percent={(score / 1000) * 100}
          status="active"
          showInfo={false}
          strokeColor={{
            '0%': '#61C26E',
            '25%': '#E9E138',
            '75%': '#F79122',
            '100%': '#FF0000',
          }}
        />
      </Row>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      <Row justify="end">
        {isComplete && score >= 500 && (
          <Button
            type="primary"
            style={
              getCurrentConversion()?.reportId
                ? { backgroundColor: '#706f6fbe' }
                : { backgroundColor: '#f7bd40' }
            }
            onClick={() => setIsModalOpen(true)}
            disabled={getCurrentConversion()?.reportId}
            loading={loading}
          >
            Report
          </Button>
        )}
      </Row>

      <Modal
        title={<div style={{ textAlign: 'center' }}>Disclaimer</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        cancelText="Cancel"
        cancelButtonProps={{ shape: 'round', style: { float: 'left', marginLeft: '100px' } }}
        okText="Accept"
        okButtonProps={{ shape: 'round', style: { marginRight: '100px' } }}
      >
        <Row justify="center" style={{ textAlign: 'center' }}>
          <Col>
            This Chatbot is not a financlal nor legal advisor, and can sometimes confidently make
            mistakes; always exercise with caution.
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default RiskBubble;
