import React, { useState, useEffect } from 'react';
import { Col, Row, Image, Card, Drawer } from 'antd';
import testImg from '@/assets/screenshot.png';
import { ReportStatus } from './reportState.js';
import { ReportTimeline } from './reportTimeline.js';
import { ClusterOutlined } from '@ant-design/icons';
import './TabPane.css';
import { useTranslation } from 'react-i18next';

const TabPane = props => {
  // console.log('props.name', props.name);
  const { t } = useTranslation();

  const details = props.name;
  const [imgSpan, setImgSpan] = useState(12); // 图片col宽度
  const [imgStatus, setStatus] = useState(12); // Details和Status col宽度
  const [serviceUrl, ServiceUrl] = useState(0); // Service Provider Information
  const [servicePhone, setServicePhone] = useState(0); // Service Provider Information
  const [colorText, setColorText] = useState(''); // Acceptance Status 颜色

  // console.log('details', details);
  useEffect(() => {
    if (details.is_url === 1 || details.is_url === 2) {
      if (details.is_url === 1) {
        setImgSpan(12);
        setStatus(12);
      }
      ServiceUrl(24);
      setServicePhone(0);
    } else {
      setImgSpan(0);
      setStatus(24);

      ServiceUrl(0);
      setServicePhone(24);
    }

    // Acceptance Status 颜色
    if (details.report_state === 'Pending Verification') {
      return setColorText('blue');
    } else if (details.report_state === 'Verified') {
      return setColorText('green');
    } else if (details.report_state === 'Resolved') {
      return setColorText('orange');
    } else if (details.report_state === 'Closed') {
      return setColorText('#88888bfa');
    } else {
      return setColorText('blue');
    }
  }, []);

  // 状态弹窗
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Row style={{ width: '100%', height: '70%' }}>
        <Col span={imgStatus} style={{ width: '100%', height: '100%' }}>
          <Row>
            {/* Details */}
            <Col span={24}>
              <Card
                size="default"
                title={t('report.tabPane.Details')}
                type="inner"
                style={{ width: '100%', height: '100%' }}
              >
                <Row>
                  <Col span={12}>{t('report.tabPane.Details.feedbackTime')} : </Col>
                  <Col span={12}>{details.feedback_time}</Col>
                </Row>
                <Row>
                  <Col span={12}>{t('report.tabPane.Details.first')} :</Col>
                  <Col span={12}>{details.first_time}</Col>
                </Row>
                <Row>
                  <Col span={12}>User Region :</Col>
                  <Col span={12}>{details.user_area}</Col>
                </Row>
                <Row>
                  <Col span={12}>Type :</Col>
                  <Col span={12}>{details.report_type}</Col>
                </Row>
                <Row>
                  <Col span={12}>Identifying content :</Col>
                  <Col span={12}>
                    {details.is_url === 1 ? (
                      <a> details.identify_content </a>
                    ) : (
                      details.identify_content
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>Location of the website :</Col>
                  <Col span={12}>{details.website_area}</Col>
                </Row>
                <Row>
                  <Col span={12}>Related brands :</Col>
                  <Col span={12}>{details.related_brands}</Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* Status */}
            <Col span={24}>
              <Card
                size="default"
                style={{ width: '100%', height: '100%' }}
                type="inner"
                title={
                  <div>
                    Status
                    <ClusterOutlined
                      style={{ color: 'green', float: 'right' }}
                      onClick={showDrawer}
                    />
                  </div>
                }
              >
                <Row>
                  <Col span={12}>Acceptance Status :</Col>
                  <Col span={12} style={{ color: `${colorText}` }}>
                    {details.report_state}
                  </Col>
                </Row>
                <br />
                <br />
                <Row className="status_col">
                  <ReportStatus name={details.report_state} />
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        {/* img imgSpan*/}
        <Col span={imgSpan} style={{ width: '100%', height: '100%' }}>
          <Card size="default" title="Screenshot" type="inner">
            <div style={{ textAlign: 'center' }}>
              <Image src={testImg} />
            </div>
          </Card>
        </Col>
      </Row>

      <Row style={{ width: '100%', height: '30%' }}>
        {/* URL and 域名 */}
        <Col span={serviceUrl}>
          <Card size="small" title="Service Provider Information" type="inner">
            <div>
              <Row>
                <Col span={8}>
                  <Card className="provider_card" title="Network provider" bordered={false}>
                    Card contentCard
                  </Card>
                </Col>
                <Col span={8}>
                  <Card className="provider_card" title="Domain registrar" bordered={false}>
                    Card content
                  </Card>
                </Col>
                <Col span={8}>
                  <Card className="provider_card" title="Domain name resolver" bordered={false}>
                    Card content
                  </Card>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        {/* 电话 and 邮件 */}
        <Col span={servicePhone}>
          <Card size="small" title="Service Provider Information" type="inner">
            <Row>
              <Col span={4}>
                <Card className="provider_card" title="Allocatee" bordered={false}>
                  Card content
                </Card>
              </Col>
              <Col span={5}>
                <Card className="provider_card" title="Allocation Date" bordered={false}>
                  Card content
                </Card>
              </Col>
              <Col span={5}>
                <Card className="provider_card" title="Latest Holder" bordered={false}>
                  Card content
                </Card>
              </Col>
              <Col span={5}>
                <Card className="provider_card" title="Latest Transfer Date" bordered={false}>
                  Card content
                </Card>
              </Col>
              <Col span={5}>
                <Card className="provider_card" title="Numbering Area" bordered={false}>
                  Card content
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {/* 状态弹窗 */}
      <Drawer title="Disposal timeline" onClose={onClose} open={open}>
        <ReportTimeline name={details.report_state} />
      </Drawer>
    </>
  );
};

export default TabPane;
