import axios from 'axios';
import request from '@/utils/request.js';

export const getSignedUrl = file => {
  return request(`/conversation/signed-url/screenshot`, {
    method: 'POST',
    data: {
      fileName: file.name,
      fileType: file.type,
    },
  });
};

export const uploadAttachment = (file, isAvatar = false) => {
  return request(`/conversation/signed-url/screenshot`, {
    method: 'POST',
    data: {
      fileName: file.name,
      fileType: file.type,
      isAvatar,
    },
  });
};

export const uploadToS3 = async (signedUrl, file, onUploadProgress, cancelToken) => {
  return axios.put(signedUrl, file, {
    cancelToken,
    onUploadProgress,
    headers: {
      'Content-Type': file.type,
    },
  });
};

export const uploadToS3WithCache = async (signedUrl, file, onUploadProgress, cancelToken) => {
  return axios.put(signedUrl, file, {
    cancelToken,
    onUploadProgress,
    headers: {
      'Content-Type': file.type,
      'Cache-Control': 'max-age=31536000',
    },
  });
};
