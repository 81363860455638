import React from 'react';
import { Col, Image, Row, Typography } from 'antd';
import logo from '@/assets/logo.png';
import '../index.css';

const HomeSenderView = () => {
  return (
    <Row align="middle" justify="center" gutter={[10, 10]} style={{ height: '100%' }}>
      <div>
        <Col
          className="logo-img"
          style={{
            display: 'flex',
            justifyContent: 'center', // 水平居中
            alignItems: 'center', // 垂直居中
            marginBottom: '1rem',
          }}
        >
          <Image width={100} src={logo} preview={false} />
        </Col>

        <Col style={{ textAlign: 'center' }}>
          <Typography.Title level={2}>Hello, I'm Scams.Report</Typography.Title>
        </Col>

        <Col style={{ textAlign: 'center' }}>
          <Typography.Text className="intro-text">
            I can help you confirm the security of your website, email address, phone number, bank
            account, please enter or send me a screenshot ~
          </Typography.Text>
        </Col>
      </div>
    </Row>
  );
};

export default HomeSenderView;
